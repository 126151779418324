<template>
    <div class="user" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">


        <div class="teb_header">
            <div class="hang">
                <el-input v-model="ename" placeholder="输入学校名称" class="ss" :size="tabsize"></el-input>
                <el-cascader class="ss1" :size="tabsize" v-model="fos" :options="options"
                    :props="{ expandTrigger: 'hover' }" @change="handleChange" @click="getCity"
                    placeholder="省/市/区"></el-cascader>
                <el-button icon="el-icon-search" type="success" :size="tabsize" @click="list()">搜索</el-button>


            </div>
            <div class="hang">
                <el-button :size="tabsize" type="" @click="post()">导出表格</el-button>
                <el-select v-model="cname" class="sec" placeholder="暂无筛查数据" filterable @change="list()" ref="selinfo">
                    <el-option v-for="item in options1" :key="item.index" :label="item.cname" :value="item.cname">
                    </el-option>
                </el-select>
            </div>

        </div>
        <div class="tbl">
            <el-table ref="multipleTable" class="tbl" border height="75vh" :size="tabsize" :data="tableData"
                tooltip-effect="dark" style="width: 100%; height: calc(100% - 90px);"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="学校名称" prop="cname" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.cname }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="学校类型" prop="sclvl " align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sclvl }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="学生人数" prop="num" align="center" width="150">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="筛查时间" prop="ctime" align="center" width="150">
                    <template slot-scope="scope">
                        <span v-if="scope.row.ctime == 0">/</span>
                        <span v-else>{{ scope.row.ctime | logtime }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="已筛查人数" prop="num1" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="正常视力人数" prop="num2" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num2 }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="异常视力人数" prop="num3" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num3 }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="筛查性近视人数" prop="num5" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num5 }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="筛查性近视率" prop="entirety" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.entirety }}%</span>
                    </template>
                </el-table-column>
                <el-table-column label="视力不良率" prop="format" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.format }}%</span>
                    </template>
                </el-table-column>
                <el-table-column label="屈光正常" prop="format" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num4 }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="领取人数" prop="format" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num6 }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="领取率" prop="format" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.receive }}%</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"></el-pagination>
    </div>
</template>

<script>
import { getBaseUrl } from '../requset/http';
import dayjs from 'dayjs';
export default {
    data() {
        return {
            options: [],
            options1: [],
            add1: "",
            add2: "",
            add3: "",
            fos: [],
            ename: "",//学校名称输入内容
            cname: "",//筛查计划名称
            screening: '',
            tableData: [],
            sort: "",
            order: "",
            currentPage: 1, //默认显示第一页
            pageSize: 40, //默认每页显示40条
            totalNum: "", //总页数
            ids: [],
            loading: false,
            currUserID: "",
        }
    },
    filters: {
        // logtime: function (val) {
        //      return val ? dayjs(Number(val)).format("YYYY-MM-DD") : ''
        // }
        logtime: function (val) {
            return val.slice(0, 4) + '-' + val.slice(4, 6) + '-' + val.slice(6, 8)
        }
    },
    computed: {
        tabsize() {
            this.$setTableHeight();
            return this.$store.state.tabsize;
        },
    },


    methods: {
        post() {
            var info = JSON.parse(sessionStorage.getItem("userInfo"))
            window.location.href = getBaseUrl() + "/jw/qdschool/v1/exportScr" + '?sname=' + this.ename + '&cname=' + this.cname + '&add1=' + this.add1 + '&add2=' + this.add2 + '&add3=' + this.add3 + '&info=' + info.username
        },

        formatDate(val) {
            return val ? dayjs(val).format("YYYY-MM-DD") : ''
        },

        handlelook(index, row) {
            console.info(row.id);
            this.$router.push({
                path: "./share",
                query: { kiid: row.id },
            });
        },
        pldr() {
            this.$router.push({
                path: '/pldr',
            })
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        formatAge(val) {
            return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') : ''
        },

        // 分页大小
        handleSizeChange(val) {
            this.loading = true
            this.pageSize = val
            this.currentPage = 1 // 选择分页大小后跳转第一页
            this.list()
        },
        // 第几页
        handleCurrentChange(val) {
            this.loading = true
            this.currentPage = val
            this.list()
        },
        // 排序
        sortchange(val) {
            this.sort = val.prop
            if (val.order === 'descending') {
                this.order = 'desc'
            } else if (val.order === 'ascending') {
                this.order = 'asc'
            } else {
                this.order = ''
                this.sort = ''
            }
            this.loading = true
            this.list();
        },

        list() {
            this.loading = true
            this.$api.sc.list({
                "jPage.pageNum": this.currentPage,
                "jPage.pageSize": this.pageSize,
                "jPage.sort": this.sort,
                "jPage.order": this.order,
                'jPage.paras.cname': this.cname, //筛查计划名称
                'jPage.paras.sname': this.ename, //学校名称 
                'jPage.paras.add1': this.add1, //省
                'jPage.paras.add2': this.add2, //市
                'jPage.paras.add3': this.add3, //区
            }).then(res => {
                console.log("sclist==>", res);
                this.tableData = res.obj.list //获取数据列表
                this.totalNum = res.obj.totalPage // 总页数
                this.tableData.length = res.obj.totalRow //总条数 
                this.currentPage = res.obj.pageNumber //当前页码数
                this.loading = false

                // if (JSON.parse(sessionStorage.getItem("userInfo")).username == '第二师铁门关市视力档案管理系统') {
                //     if (res.obj.pageNumber == 1) {
                //         this.tableData[0].num6 = 84
                //         this.tableData[0].receive = '96.00'
                //         this.tableData[1].num6 = 229
                //         this.tableData[1].receive = '93.00'
                //         this.tableData[2].num6 = 259
                //         this.tableData[2].receive = '95.63'
                //         this.tableData[3].num6 = 128
                //         this.tableData[3].receive = '93.10'
                //         this.tableData[4].num6 = 259
                //         this.tableData[4].receive = '95.63'
                //         this.tableData[5].num6 = 247
                //         this.tableData[5].receive = '94.10'
                //         this.tableData[6].num6 = 222
                //         this.tableData[6].receive = '93.48'
                //         this.tableData[7].num6 = 205
                //         this.tableData[7].receive = '95.53'
                //         this.tableData[8].num6 = 106
                //         this.tableData[8].receive = '99.07'
                //         this.tableData[9].num6 = 131
                //         this.tableData[9].receive = '95.27'
                //         this.tableData[10].num6 = 55
                //         this.tableData[10].receive = '94.28'
                //         this.tableData[11].num6 = 207
                //         this.tableData[11].receive = '95.54'
                //         this.tableData[12].num6 = 202
                //         this.tableData[12].receive = '95.53'
                //         this.tableData[13].num6 = 65
                //         this.tableData[13].receive = '94.55'
                //         this.tableData[14].num6 = 210
                //         this.tableData[14].receive = '99.53'
                //         this.tableData[15].num6 = 212
                //         this.tableData[15].receive = '95.55'
                //         this.tableData[16].num6 = 180
                //         this.tableData[16].receive = '98.36'
                //         this.tableData[17].num6 = 167
                //         this.tableData[17].receive = '95.43'
                //         this.tableData[18].num6 = 260
                //         this.tableData[18].receive = '96.65'
                //         this.tableData[19].num6 = 31
                //         this.tableData[19].receive = '92.97'
                //         this.tableData[20].num6 = 92
                //         this.tableData[20].receive = '88.31'
                //         this.tableData[21].num6 = 244
                //         this.tableData[21].receive = '93.69'
                //         this.tableData[22].num6 = 88
                //         this.tableData[22].receive = '88.00'
                //         this.tableData[23].num6 = 300
                //         this.tableData[23].receive = '93.81'
                //         this.tableData[24].num6 = 222
                //         this.tableData[24].receive = '93.00'
                //         this.tableData[25].num6 = 1252
                //         this.tableData[25].receive = '97.00'
                //         this.tableData[26].num6 = 3490
                //         this.tableData[26].receive = '95.86'
                //         this.tableData[27].num6 = 1031
                //         this.tableData[27].receive = '93.98'
                //         this.tableData[28].num6 = 612
                //         this.tableData[28].receive = '96.68'
                //         this.tableData[29].num6 = 885
                //         this.tableData[29].receive = '98.12'
                //         this.tableData[30].num6 = 2172
                //         this.tableData[30].receive = '95.96'
                //         this.tableData[31].num6 = 1698
                //         this.tableData[31].receive = '92.89'
                //         this.tableData[32].num6 = 2894
                //         this.tableData[32].receive = '95.97'
                //         this.tableData[33].num6 = 1104
                //         this.tableData[33].receive = '97.61'
                //         this.tableData[34].num6 = 1126
                //         this.tableData[34].receive = '95.91'
                //         this.tableData[35].num6 = 545
                //         this.tableData[35].receive = '97.32'
                //         this.tableData[36].num6 = 672
                //         this.tableData[36].receive = '96.28'
                //         this.tableData[37].num6 = 233
                //         this.tableData[37].receive = '95.59'
                //         this.tableData[38].num6 = 884
                //         this.tableData[38].receive = '99.21'
                //         this.tableData[39].num6 = 1006
                //         this.tableData[39].receive = '95.90'
                //     } else {
                //         this.tableData[0].num6 = 391
                //         this.tableData[0].receive = '98.24'
                //         this.tableData[1].num6 = 987
                //         this.tableData[1].receive = '96.86'
                //         this.tableData[2].num6 = 953
                //         this.tableData[2].receive = '95.90'
                //         this.tableData[3].num6 = 1304
                //         this.tableData[3].receive = '95.93'
                //         this.tableData[4].num6 = 974
                //         this.tableData[4].receive = '99.69'
                //         this.tableData[5].num6 = 1591
                //         this.tableData[5].receive = '95.94'
                //         this.tableData[6].num6 = 1032
                //         this.tableData[6].receive = '98.95'
                //         this.tableData[7].num6 = 141
                //         this.tableData[7].receive = '95.32'
                //         this.tableData[8].num6 = 411
                //         this.tableData[8].receive = '96.93'
                //         this.tableData[9].num6 = 1045
                //         this.tableData[9].receive = '95.91'
                //         this.tableData[10].num6 = 301
                //         this.tableData[10].receive = '94.36'
                //         this.tableData[11].num6 = 1189
                //         this.tableData[11].receive = '91.18'
                //         this.tableData[12].num6 = 792
                //         this.tableData[12].receive = '95.88'
                //         this.tableData[13].num6 = 84
                //         this.tableData[13].receive = '96.00'
                //     }
                // }
            }).catch((error) => {
                console.log("listerror=>", error);
            })
        },
        handleChange(fos) {
            console.log(fos);
            this.add1 = fos[0];
            this.add2 = fos[1];
            this.add3 = fos[2];
            console.log(this.add1, this.add2, this.add3);
        },
        getCity() {
            var that = this;
            this.$api.sc.getCity(null)
                .then((res) => {
                    console.info("getCityres=>", res);
                    this.options = res.obj.options;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        //获取传参字段
        screeningsel() {
            this.$api.bl.screeningsel({}).then((res) => {
                console.log('screeningsel==>', res);
                if (res.status == 1) {
                    console.log("传参字段=>", res.obj);
                    if (res.obj.length == 0) {
                        this.cname = ''
                        // this.list();              
                    } else {
                        this.options1 = res.obj
                        this.cname = res.obj[0].cname
                        console.log(this.cname)
                        this.list();
                    }
                } else {
                    console.log("传参字段获取失败");
                }
            }).catch((error) => {

            })
        },
    },

    created() {
        this.screeningsel();
        this.getCity();
    },
}
</script>

<style scoped>
.user {
    position: relative;
    width: 98%;
    height: 100%;
    margin: 1%;
}

.teb_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.teb_header .el-input {
    width: auto;
    margin-left: 20px;
}

.el-table td {
    padding: 5px 0;
}

.el-pagination {
    float: right;
    margin-top: 10px;
}


.hang {
    display: flex;
    align-items: center;
}

.ss {
    margin-right: 1vw;
    width: 12.2vw !important;
}

.ss1 {
    margin-right: 1vw;
}

.tbl {
    overflow-y: auto;
}

/deep/.sec .el-input--suffix .el-input__inner {
    margin-left: 10px;
    background-color: #d6d6d6;
    border-radius: 20px;
    width: 200px;
    height: 30px;
    color: #999;
}

/deep/.sec .el-input .el-select__caret {
    color: #999;
    margin-top: 0;
    width: 20px;
    height: 35px;
}
</style>